.about-us-v1 {
	margin-top: var(--page-margin-top);

	/*	.inner-banner {
		background-image: url(RESOURCE/img/bg-wir.jpg);
	}  */

	.team-members {
		display: flex;
		grid-template-columns: repeat(auto-fill, minmax(min(var(--about-us-grid-width), 100%), 1fr));
		grid-gap: 1em;
		justify-content: center;

		@media (max-width: 767px) {
			flex-wrap: wrap;
		}

		.member {
			width: 20%;

			@media (max-width: 991px) {

				width: 25%;
			}

			@media (max-width: 767px) {
				max-width: 250px;
				width: 100%;
			}
		}
	}

	.member-info {
		text-align: center;
		background-color: var(--color-white);

		.name {
			font-weight: 600;
		}

		.resort {
			font-size: var(--font-size-md);
			color: var(--color-grey-dark);
			margin-bottom: 10px;
		}

		.phone {
			font-size: var(--font-size-md);
		}

		.email {
			font-size: var(--font-size-md);
		}

	}


}